
<!--
 * @Description: 修改订单房间
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:42:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-21 11:47:19
-->

<template>
  <div class="order_edit_room room_list">
    <div v-for="(item, i) in accomRoomList" :key="item">
      <div class="item">
        <!-- <el-date-picker
          v-model="item.check_in_date"
          type="date"
          style="width: 150px"
          :clearable="false"
          class="date_picker"
          format="MM-DD入住"
          value-format="YYYY-MM-DD"
          placeholder="选择入住日期"
          :editable="false"
          :disabled="!item.boolUpdatable"
          @change="dateChange"
        ></el-date-picker> -->
        <el-date-picker
          v-model="item.dates"
          type="daterange"
          :clearable="false"
          class="date_picker"
          format="YYYY年MM月DD日"
          unlink-panels
          value-format="YYYY-MM-DD"
          start-placeholder="选择入住日期"
          end-placeholder="选择离店日期"
          :editable="false"
          :disabled="!item.boolUpdatable"
          @change="dateRangeChange(item)"
        ></el-date-picker>
        <el-popover placement="bottom" :width="200" trigger="click">
          <template #reference>
            <el-button class="btn_day_picker" :disabled="!item.boolUpdatable"
              >{{ item.days }}晚</el-button
            >
          </template>
          <el-input-number
            v-model="item.days"
            :min="1"
            @change="daysChange(item)"
          />
        </el-popover>
        <el-cascader
          placeholder="选择房间"
          v-model="item.storeIds"
          class="cascader_choose_room"
          style="width: 300px"
          :options="groupList"
          :props="roomProps"
          filterable
          :disabled="!item.boolUpdatable"
          @change="roomChange"
        >
          <template #default="{ node, data }">
              <span>{{ data.name }}</span>
              <span v-if="!node.isLeaf">({{ data.shop_name }}) ({{ data.children.length }}) </span>
          </template>
        </el-cascader>
        <!-- <el-input
          v-model="item.decimal_amount"
          class="input_total_amount"
          placeholder
          @change="amountChange(item)"
          style="width: 120px;"
        >
          <template #prefix>￥</template>
        </el-input>-->
        <el-popover
          placement="bottom"
          :width="640"
          trigger="click"
          @hide="popoverHide"
        >
          <template #reference>
            <el-input
              v-model="item.decimal_amount"
              class="input_total_amount"
              placeholder
              style="width: 100px"
              :disabled="!item.boolUpdatable"
              @input="amountInput(item)"
            >
              <template #prefix>￥</template>
            </el-input>
          </template>
          <!-- 房间金额 -->
          <div class="date_price_height">
            <el-scrollbar max-height="300px">
              <div class="date_price_grid">
                <div class="grid_item" v-for="x in item.datePrices" :key="x">
                  <div class="date">{{ x.date }}</div>
                  <div class="price">
                    <el-input
                      v-model="x.decimal_price"
                      placeholder
                      style="width: 100px"
                      :disabled="!item.boolUpdatable"
                      @input="priceChange(item, x)"
                    >
                      <template #prefix>￥</template>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </el-popover>
        <el-popover placement="bottom" :width="760" trigger="click">
          <template #reference>
            <el-button
              type="primary"
              link
              size="small"
              :disabled="!item.boolUpdatable"
              style="margin-left: 10px"
            >
              入住人({{ item.residents ? item.residents.length : 0 }})
            </el-button>
          </template>
          <el-table :data="item.residents" border>
            <el-table-column label="姓名" width="120">
              <template #default="scope">
                <el-input
                  v-model="scope.row.name"
                  placeholder="请输入姓名"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="160">
              <template #default="scope">
                <el-input
                  v-model="scope.row.mobile_phone"
                  clearable
                  placeholder="请输入手机号"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="证件类型" width="120">
              <template #default="scope">
                <el-select v-model="scope.row.card_type" placeholder>
                  <el-option
                    :label="x.text"
                    :value="x.value"
                    v-for="x in enums.card_types"
                    :key="x.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="证件号">
              <template #default="scope">
                <el-input
                  v-model="scope.row.card_no"
                  clearable
                  placeholder="请输入证件号码"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label width="80">
              <template #header>
                <el-button
                  round
                  type="success"
                  size="small"
                  @click="addResident(item)"
                  >+添加</el-button
                >
              </template>
              <template #default="scope">
                <el-icon
                  style="margin-left: 20px"
                  @click="item.residents.splice(scope.$index, 1)"
                >
                  <Delete />
                </el-icon>
              </template>
            </el-table-column>
          </el-table>
        </el-popover>

        <el-button
          type="danger"
          icon="Delete"
          size="small"
          link
          @click="onDelRoom(i)"
          v-if="!item.id"
        ></el-button>
      </div>
      <div class="help-block" v-if="item.unusable" style="color: #f56c6c">
        存在无法预定的日期,{{ item.occupyDates.join(",") }}
      </div>
    </div>

    <div class="buttons">
      <el-button type="primary" size="small" plain @click="onAddRoom"
        >+添加房间</el-button
      >
    </div>
  </div>
</template>

<script>
import date_util from "@/utils/date_util";
import common_api from "@/http/common_api";

export default {
  data() {
    return {
      //检测选择的房源数据返回结果列表
      accomRoomList: [],
      //分组的房屋选则
      groupList: [],
      roomProps: {
        // children: "store_list",
        value: "id",
        label: "name",
        // expandTrigger: 'hover'
      },
      enums: {},
    };
  },
  props: ["choose_room"],
  emits: ["close", "beforeCheck", "change", "checkChange"],
  watch: {
    choose_room: {
      handler() {
        if (Array.isArray(this.choose_room) && this.choose_room.length > 0) {
          // console.log(this.choose_room);
          var totalAmount = 0;
          this.choose_room.forEach((item) => {
            if (item.status != 50) {
              this.accomRoomList.push({
                id: item.id,
                check_in_date: item.checkin_date,
                check_out_date: item.checkout_date,
                decimal_amount: item.decimal_amount,
                dates: [item.checkin_date, item.checkout_date],
                days: item.days,
                storeIds: [item.store.group_id, item.store.id],
                datePrices: item.datePriceList,
                residents: item.residents,
                store_id: item.store_id,
                boolUpdatable: item.boolUpdatable,
              });
              totalAmount += item.decimal_amount;
            }
          });

          this.$emit("change", this.accomRoomList, totalAmount);
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    this.$http.post("seller/v1/roomState/stores").then((res) => {
      if (res.code == 0) {
        this.groupList = [];
        res.data.forEach((x) => {
          var m = {
            id: x.id,
            name: x.name ,
            shop_name:x.shop_name,
            children: [],
          };
          x.store_list.forEach((item) => {
            m.children.push({
              id: item.id,
              name: item.house_no,
            });
          });

          this.groupList.push(m);
        });
      }
    });
  },
  methods: {
    /**
     * 添加房间
     */
    onAddRoom() {
      var item = {};
      item = {
        days: 1,
        check_in_date: date_util.formatDate(new Date()),
        boolUpdatable: true,
      };
      this.accomRoomList.push(item);
    },
    /**
     * 删除房间
     */
    onDelRoom(i) {
      this.accomRoomList.splice(i, 1);
    },
    /**
     * 日期选择更改
     */
    dateChange() {
      this.onGetCheck();
    },

    dateRangeChange(item) {
      item.check_in_date = item.dates[0];
      item.check_out_date = item.dates[1];
      var time =
        new Date(item.dates[1]).getTime() - new Date(item.dates[0]).getTime();
      item.days = time / 3600 / 24 / 1000;

      this.onGetCheck();
    },

    /**
     * 天数更改
     */
    daysChange(item) {
      var dt = new Date(item.check_in_date);
      var time = dt.setDate(dt.getDate() + item.days);
      item.check_out_date = date_util.formatDate(time);
      item.dates[1] = item.check_out_date;

      this.onGetCheck();
    },

    /**
     * 房间更改
     */
    roomChange() {
      this.onGetCheck();
    },

    /**
     * 房间总价更改
     */
    amountInput(item) {
      if (item.decimal_amount && !isNaN(item.decimal_amount)) {
        var idx = item.decimal_amount.indexOf(".");
        if (idx > -1 && item.decimal_amount.length - idx == 1) {
          return;
        }

        var price = parseFloat(
          item.decimal_amount / item.datePrices.length
        ).toString();
        if (price.indexOf(".") > -1) {
          price = parseFloat(price.substring(0, price.lastIndexOf(".") + 3));
        }
        var temp_amount = 0;
        item.datePrices.forEach((x) => {
          x.decimal_price = parseFloat(price);
          temp_amount += x.decimal_price;
        });
        var x = parseFloat(
          (
            item.decimal_amount -
            temp_amount +
            item.datePrices[0].decimal_price
          ).toFixed(2)
        );
        item.datePrices[0].decimal_price = x;
      } else {
        item.datePrices.forEach((x) => {
          x.decimal_price = "0";
        });
      }
    },

    /**
     * 单价更改
     */
    priceChange(item, m) {
      if (!isNaN(m.decimal_price)) {
        var idx = m.decimal_price.indexOf(".");
        if (idx > -1 && m.decimal_price.length - idx == 1) {
          return;
        }

        var amount = 0;
        item.datePrices.forEach((x) => {
          if (x.decimal_price && !isNaN(x.decimal_price)) {
            amount += Math.floor(parseFloat(x.decimal_price * 100));
          }
        });
        item.decimal_amount = amount / 100;
      }
    },

    /**
     * 总额数值更改，失去焦点时候
     */
    popoverHide() {
      this.onGetCheck();
    },

    // /**
    //  * 总价获得焦点
    //  */
    // amountFocus(item) {
    //   console.log(item);
    //   item.is_focus = true;
    // },
    // /**
    //  * 总价失去焦点
    //  * @param {*} item
    //  */
    // amountBlur(item) {
    //   item.is_focus = false;
    // },

    /**
     * 添加入住人
     */
    addResident(item) {
      if (!item.residents) {
        item.residents = [];
      }
      item.residents.push({
        name: "",
        mobile_phone: "",
        card_no: "",
        card_type: 0,
      });
    },

    // /**
    //  * 计算总金额
    //  */
    // caclTotalAmount() {
    //   this.totalAmount = 0;
    //   var temp = 0;
    //   this.accomRoomList.forEach((item) => {
    //     temp += parseFloat(item.decimal_price);
    //   });
    //   this.totalAmount = temp.toFixed(2);
    // },

    /**
     * 获取check的数据
     */
    onGetCheck() {
      this.$emit("checkChange", true);
      this.accomRoomList.forEach((item) => {
        if (!item.days) {
          item.days = 1;
        } else {
          item.days = parseInt(item.days);
        }
        item.store_id = item.storeIds
          ? item.storeIds[item.storeIds.length - 1]
          : 0;
      });
      var data_list = this.accomRoomList.map((o) => {
        return {
          check_in_date: o.check_in_date,
          datePrices: o.datePrices,
          days: o.days,
          decimal_amount: o.decimal_amount,
          id: o.id,
          store_id: o.store_id,
        };
      });
      this.isChecking = true;
      this.$http.post("seller/v1/houseOrder/check", data_list).then((res) => {
        var total_amount = 0;
        if (res.code == 0) {
          for (var i = 0; i < this.accomRoomList.length; i++) {
            var item = res.data[i];
            this.accomRoomList[i].decimal_amount = item.decimal_amount;
            this.accomRoomList[i].unusable = item.unusable;
            this.accomRoomList[i].occupyDates = item.occupyDates;
            this.accomRoomList[i].datePrices = item.datePrices;
            total_amount += Math.floor(parseFloat(item.decimal_amount) * 100);
          }
          var totalAmount = total_amount / 100;
          this.$emit("change", this.accomRoomList, totalAmount);
          this.$emit("checkChange", false);
        }
      });
    },
  },
};
</script>

<style scoped>
.room_list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.room_list .item .el-date-picker {
  width: 80px;
}

.room_list .item .btn_day_picker {
  width: 100px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.room_list .item .el-select {
  flex-grow: 1;
  width: auto;
}

.room_list .item .el-input {
  width: 140px;
}

.room_list .buttons {
  margin-top: 10px;
}

/* 价格列表 */
/* .date_price_height {
  max-height: 400px;
} */

.date_price_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

.date_price_grid .grid_item .date {
  font-weight: 600;
}
</style>

<style>
.order_edit_room .date_picker .el-input__wrapper,
.order_edit_room .cascader_choose_room .el-input__wrapper {
  border-radius: 0px;
}

.order_edit_room .el-range-editor.el-input__wrapper {
  border-radius: 0px;
}

.order_edit_room .el-date-editor.el-input__wrapper {
  flex-grow: unset;
}

.order_edit_room .input_total_amount .el-input__wrapper {
  border-radius: 0px;
  height: 32px;
  box-shadow: none;
  border: 1px solid var(--el-border-color);
  border-left: 0;
}

/* .date_price_height .el-scrollbar .el-scrollbar__wrap {
  max-height: 350px;
  overflow-x: hidden;
} */
</style>